<template lang="pug">

header.vs-navbar.vx-navbar.navbar-custom.navbar-skelton.vs-navbar-null.vs-navbar-color-#fff
  div.vs-con-items
    div.navbar-collapse.flex-row-sb
      div.row.w-100
        div.search-block.col-6
          Search(v-if="checkAccess('displaySearch')")
        div.col-6.header-right
          div.pr-10.cursor-pointer
            router-link(
              to="/user-notification"
              replace)
              v-icon(:color="active === 'userNotification' ? 'primary' : ''") mdi-bell-ring-outline
              b-badge(v-if="userNotification.unchecked_notification" pill)
                | {{ userNotification.unchecked_notification }}
          v-switch.ml-3(v-if="isDisplaySwitchLang" v-model="isLang" :label="`${$t(lang)}`" @click="reloadPage")
          div
            b-dropdown(
              id="dropdown-1"
              class="userName p-0"
              variant="outline-primary"
              right
              no-caret)
              template(#button-content)
                div(v-if="(checkAccess('crewingManager') || checkAccess('crewingHead')) && !checkAccess('backOffice')")
                  span.userName-name
                    | {{ user.crewing_company ? user.crewing_company.name_ukr : '' }}
                    span.userName-login.ml-2
                      | (ID: {{ user.id }})
                  span(v-if="user.type_user === 'crewing_manager'") {{securityCode.code}}
                div(v-else)
                  span.userName-name {{ user.name }}
                  span.userName-login {{ user.username }}
                    span (ID: {{ user.id }})
              b-dropdown-item(@click="$router.push({ name: 'user-setting', query: { isAuthentication: true } })") {{ $t('authentication') }}
              b-dropdown-item(@click="logout") {{ $t('logout') }}
</template>

<script>
import Search from '@/components/molecules/Search/Search.vue'
import ListItem from '@/components/atoms/ListItem'
import { checkAccess } from '@/mixins/permissions'
import { mapActions, mapMutations, mapState } from 'vuex'
import i18n from '@/plugins/localization/index'

export default {
  name: 'TopBar',
  components: {
    Search,
    ListItem
  },
  props: {
    active: String
  },
  data () {
    return {
      tabs: 0,
      isDialog: false,
      checkAccess,
      isLang: false,
      isDisplaySwitchLang: process.env.VUE_APP_DISPLAY_SWITCH_LANG,
      securityCode: {
        valid_to: new Date(),
        code: null
      }
    }
  },
  computed: {
    ...mapState({
      lang: (state) => state.main.lang,
      userNotification: (state) => state.main.userNotification,
      crewingHost: (state) => state.main.crewingHost,
      flyairxpressHost: (state) => state.main.flyairxpressHost,
      user: (state) => state.main.user
    })
  },
  watch: {
    isLang (val) {
      this.changedLang(val ? 'ua' : 'bo')
    }
  },
  mounted () {
    let sessionLang = sessionStorage.getItem('sessionLang')
    this.isLang = window.location.hostname !== 'googie.com.ua'
    if (sessionLang) {
      this.SET_STATE_DATA_MAIN({ lang: sessionLang })
      this.isLang = sessionLang !== 'bo'
    }
    this.$auth.isAuth() && this.getInfoNotify()
    this.user.type_user === 'crewing_manager' && this.checkUserCode()
  },
  methods: {
    ...mapMutations(['SET_STATE_DATA_MAIN']),
    ...mapActions(['getSecurityCode', 'getSecurityCode']),
    changedLang (lang) {
      this.SET_STATE_DATA_MAIN({ lang: lang })
      lang === 'bo' ? (this.isLang = false) : (this.isLang = true)
      i18n.locale = lang
      sessionStorage.setItem('sessionLang', lang)
    },
    logout () {
      localStorage.removeItem('TOKEN')
      document.cookie = 'TOKEN' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
      window.location = '/login'
      sessionStorage.clear()
    },

    getImgByLang () {
      let images = require.context('@/assets/img/', false, /\.svg$/)
      return images(`./Flag_${this.lang === 'bo' ? '' : this.lang}.svg`)
    },

    getInfoNotify () {
      this.$api.get('api/v1/auth/user_notification/').then((response) => {
        if (response.code === 200) this.$store.commit('SET_STATE_DATA_MAIN', { userNotification: response.data })
      })
    },

    openAuthTOTP () {
      this.$refs.totpAuth.isDialog = true
    },
    setUpdateTimer (validTo) {
      clearTimeout(this.timeoutCode)
      const updateAt = new Date(validTo).getTime() - new Date().getTime()
      this.timeoutCode = setTimeout(() => this.checkUserCode(), updateAt)
    },
    checkUserCode () {
      if (new Date(this.securityCode.valid_to).getTime() <= new Date().getTime()) {
        this.getSecurityCode()
          .then((response) => {
            this.securityCode = response.data
            if (response.code === 200) {
              this.setUpdateTimer(response.data.valid_to)
            }
          })
      } else this.setUpdateTimer(this.securityCode.valid_to)
    },

    reloadPage () {
      location.reload()
    }
  }
}
</script>

<style>
  .search-block {
    border-right: 1px solid #dfe3e7;
  }
  .btn[class*='btn-outline-'] {
    font-size: 1.1rem;
  }
  .form-control {
    height: 2.5rem !important;
  }
  .navbar-collapse {
    height: 62px;
  }
  .infoBlock {
    position: absolute;
    right: 34rem;
    top: 3rem;
    width: 25rem;
  }
</style>
